
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";

interface tambahArea {
  name: string;
  picArea: string;
  note: string;
  email: any;
  phone: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
}

interface onlyIdBank {
  idBank: string;
}

interface items {
  listBank: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
}

export default defineComponent({
  name: "Area",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form,
    Multiselect,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      listBank: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
    });

    const tambahAreaValidator = Yup.object().shape({
      name: Yup.string().required("Nama Area Wajib Diisi"),
      picArea: Yup.string().required("Penanggung Jawab Wajib Diisi"),
      phone: Yup.string().required("Nomor Telepon Wajib Diisi"),
      email: Yup.string().required("Email Wajib Diisi").email(),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
    });

    const tambahArea = reactive<tambahArea>({
      name: "",
      picArea: "",
      note: "",
      email: "",
      phone: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
    });

    const onlyIdBank = reactive<onlyIdBank>({
      idBank: "",
    });

    const saveChanges1 = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      isLoading.value = true;

      // dummy delay
      setTimeout(() => {
        // Send login request

        ApiService.postWithData(
          "crmv2/main_bank/bank/office/area/" + onlyIdBank.idBank,
          tambahArea
        )
          .then((res) => {
            toast.success("Berhasil Tambah Area");
            isLoading.value = false;

            router.push({ path: "/superadmin/area" });
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    const getListBank = () => {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          items.listBank = response.data;
        }
      );
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === tambahArea.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === tambahArea.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kecamatanOptions = response.data;
        });
      }
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      setCurrentPageBreadcrumbs("Area", ["Data"]);
      getProvinsi();
      getListBank();
    });

    return {
      submitButton,
      saveChanges1,
      tambahArea,
      tambahAreaValidator,
      onlyIdBank,
      items,
      getKabupaten,
      getKecamatan,
      getListBank,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
